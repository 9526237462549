:root {
  --dark-color: #000;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    filter: blur(0.1vmin);
    background-image: radial-gradient(ellipse at top,
        transparent 0%,
        var(--dark-color)),
      radial-gradient(ellipse at bottom,
        var(--dark-color),
        rgba(145, 233, 255, 0.2)),
      repeating-linear-gradient(220deg,
        rgb(0, 0, 0) 0px,
        rgb(0, 0, 0) 19px,
        transparent 19px,
        transparent 22px),
      repeating-linear-gradient(189deg,
        rgb(0, 0, 0) 0px,
        rgb(0, 0, 0) 19px,
        transparent 19px,
        transparent 22px),
      repeating-linear-gradient(148deg,
        rgb(0, 0, 0) 0px,
        rgb(0, 0, 0) 19px,
        transparent 19px,
        transparent 22px),
      linear-gradient(90deg, rgb(0, 255, 250), rgb(240, 240, 240));
}

#play-button {
  position: fixed;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
  z-index: 2;
  cursor: pointer;
  background: transparent;
  border: none;
  cursor: pointer;
}

.play-icon {
  fill: white;
  width: 120px;
  height: 120px;
}

.play-icon__play {
  opacity: 1;
}

.play-icon__pause {
  opacity: 0;
}

.play-icon--playing .play-icon__play {
  opacity: 0;
}

.play-icon--playing .play-icon__pause {
  opacity: 1;
}

.loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg,
      rgba(27, 17, 77, 1) -8.35%,
      rgba(29, 22, 87, 1) -5.1%,
      rgba(51, 65, 174, 1) 28.53%,
      rgba(59, 82, 208, 1) 43.72%,
      rgba(53, 100, 213, 1) 51.31%,
      rgba(37, 146, 225, 1) 67.59%,
      rgba(12, 219, 245, 1) 89.28%,
      rgba(0, 255, 255, 1) 100.13%);
  transform: rotate(-180deg);
  transition: opacity 3s;
  opacity: 1;
}

.loading--complete {
  opacity: 0;
  z-index: -1;
}


.loading svg {
  display: block;
  margin: auto;
  width: 10rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  shape-rendering: auto;
}

.loading path {
  fill: none;
  stroke: #ff0cb8;
  transform: scale(0.8);
  transform-origin: 50px 50px;
}